import React, { useState } from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Hamburger from "./Hamburger"
import Menu from "./Menu"
import Logo from "../../../images/brand/logo.svg"

const Outer = styled.nav`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  padding: 0 24px;
  align-items: center;
  z-index: 1; ;
`
const Title = styled.h1`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  color: ${props => props.theme.wine};
  background: rgba(241, 236, 235, 0.8);
  padding: 16px 4px;
  border-radius: 4px;
`
const Text = styled.div`
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LogoImg = styled.img`
  height: 18px;
  margin: 0;
  @media (max-width: 320px) {
    transform: scale(0.8);
    transform-origin: center;
  }
`

export default function Nav({
  question,
  title,
  logoOverride,
  hideHamburger,
  leftContainer,
}) {
  const [open, setOpen] = useState(false)
  return (
    <Outer>
      {leftContainer}
      <Text>
        {title ? (
          <Title>{title}</Title>
        ) : (
          <AniLink to="/" cover bg="#FF6673">
            <LogoImg
              src={logoOverride ? logoOverride : Logo}
              alt="Different is normal logo"
            />
          </AniLink>
        )}
      </Text>
      <Hamburger
        hideHamburger={hideHamburger}
        onClick={() => setOpen(!open)}
        isOpen={open}
      />
      <Menu isOpen={open} />
    </Outer>
  )
}
