export const urls = {
  ABOUT: "/intro/about/",
  INTRO: "/intro/",
  INTRO_END: "/intro/next-step/",
  TERMS: "/intro/terms/",
  FOUROHFOUR: "/404/",
  SHARE: "/share/",
  LANDING: "/",
  KYV: {
    CARDS: "/know-your-vulva/",
    QUIZ: "/know-your-vulva/hotspot/",
    NEXT: "/know-your-vulva/next-step/",
    RESULTS: "/know-your-vulva/results/",
    SUMMARY: "/know-your-vulva/summary",
  },
  LYV: {
    QUIZ: "/love-your-vulva/quiz/",
    OUTRO: "/love-your-vulva/outro/",
    NEXT: "/love-your-vulva/next-step/",
  },
  VH: {
    CARDS: "/vulva-health/",
    QUIZ: "/vulva-health/quiz/",
    OUTRO: "/vulva-health/outro/",
    NEXT: "/vulva-health/next-step/",
  },
  VM: {
    INTRO: "/vulva-maker/",
    MAKER: "/vulva-maker/maker",
    FINISH: "/vulva-maker/finished",
  },
}
