import { injectGlobal } from "emotion"

injectGlobal`
@font-face {
    font-family: 'Alt Goth';
    src: url('/fonts/alternate_gothic_no2_bt-webfont.woff2') format('woff2'),
        url('/fonts/alternate_gothic_no2_bt-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/exljbris_-_museosans-500-webfont.woff2') format('woff2'),
        url('/fonts/exljbris_-_museosans-500-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Museo Sans';
    src: url('/fonts/exljbris_-_museosans-700-webfont.woff2') format('woff2'),
        url('/fonts/exljbris_-_museosans-700-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
`
