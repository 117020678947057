import { useLayoutEffect, useState } from "react"

export function useViewableHeight() {
  const [viewableHeight, setViewableHeight] = useState(0);

  useLayoutEffect(() => {
    function setHeight() {
      setViewableHeight(window?.visualViewport?.height || document.documentElement?.clientHeight || window.innerHeight)
    }
    setHeight();
    window.addEventListener('resize', setHeight);
    return () => {
      window.removeEventListener('resize', setHeight);
    }
  })

  return viewableHeight;
}
