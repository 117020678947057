import React from "react"
import styled from "@emotion/styled"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Logo from "../../../images/brand/logo-white.svg"
import { useCookies } from "react-cookie"
import { urls } from "../../../data/urls"

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.cherry};
  z-index: 1;
  display: grid;
  grid-template-rows: 56px 2fr 120px;
  gap: 32px;
  flex-wrap: wrap;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  pointer-events: ${props => (props.isOpen ? "all" : "none")};
  transition: 0.4s;
  p {
    margin: 0;
  }
`
const CustomLink = styled(AniLink)`
  color: ${props => props.theme.apricot};
  text-align: center;
  text-decoration: none;
  font-family: "Alt Goth", sans-serif;
  font-size: 2.4rem;
`
const NavLinks = styled.div`
  margin: auto 0;
  padding: 0 32px;
  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  align-items: center;
`
const Brand = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const LogoImg = styled.img`
  height: 18px;
  margin: 0;
  @media (max-width: 320px) {
    transform: scale(0.8);
    transform-origin: center;
  }
`
const Details = styled.div`
  color: #ffffff;
  text-align: center;
  padding: 0 32px;
  font-size: 0.8rem;
`

function AboutFallbackLink({ to, isDemographicSet, children }) {
  if (isDemographicSet) {
    return (
      <CustomLink to={to} cover bg="#FF6673">
        {children}
      </CustomLink>
    )
  } else {
    return (
      <CustomLink
        to="/intro/about/"
        cover
        bg="#FF6673"
        state={{
          destination: to,
        }}
      >
        {children}
      </CustomLink>
    )
  }
}

export default function Menu({ isOpen }) {
  const [cookies] = useCookies([])
  const isDemographicSet = cookies.demographic

  return (
    <Container isOpen={isOpen}>
      <Brand>
        <LogoImg src={Logo} alt="Different is normal logo" />
      </Brand>
      <NavLinks>
        <CustomLink to="/" cover bg="#FF6673">
          Home
        </CustomLink>
        <AboutFallbackLink
          to={urls.KYV.QUIZ}
          isDemographicSet={isDemographicSet}
        >
          Know Your Vulva
        </AboutFallbackLink>
        <AboutFallbackLink
          to={urls.LYV.QUIZ}
          isDemographicSet={isDemographicSet}
        >
          Love Your Vulva
        </AboutFallbackLink>
        <AboutFallbackLink
          to={urls.VH.QUIZ}
          isDemographicSet={isDemographicSet}
        >
          Vulva Health
        </AboutFallbackLink>
        <AboutFallbackLink
          to={urls.VM.INTRO}
          isDemographicSet={isDemographicSet}
        >
          Vulva Maker
        </AboutFallbackLink>
      </NavLinks>
      <Details>
        <p>
          Developed by Brook in partnership with Canesten.© 2020. Developed by
          Thompson
        </p>
      </Details>
    </Container>
  )
}
