import React from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  width: 32px;
  height: 12px;
  text-align: right;
  position: absolute;
  right: 24px;
  top: 20px;
  z-index: 2;
  cursor: pointer;
  display: ${props => (props.hideHamburger ? "none" : "block")};
`
const LineTop = styled.div`
  width: 32px;
  height: 2px;
  background: ${props => props.theme.apricot};
  top: ${props => (props.isOpen ? "5px" : "0")};
  transform: ${props => (props.isOpen ? "rotate(45deg)" : "rotate(0deg)")};
  position: absolute;
  transition: 0.4s;
`
const LineBot = styled.div`
  width: 32px;
  height: 2px;
  background: ${props => props.theme.apricot};
  bottom: ${props => (props.isOpen ? "5px" : "0")};
  transform: ${props => (props.isOpen ? "rotate(-45deg)" : "rotate(0deg)")};
  position: absolute;
  transition: 0.4s;
`

export default function Hamburger({ onClick, isOpen, hideHamburger }) {
  return (
    <Container hideHamburger={hideHamburger} onClick={onClick} isOpen={isOpen}>
      <LineTop isOpen={isOpen} />
      <LineBot isOpen={isOpen} />
    </Container>
  )
}
