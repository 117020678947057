import React from "react"
import { ThemeProvider } from "emotion-theming"
import theme from "../../utils/theme"
import styled from "@emotion/styled"
import "../../utils/fonts"
import { ToastContainer } from "react-toastify"
import { CookiesProvider } from "react-cookie"
import "react-toastify/dist/ReactToastify.min.css"

import Nav from "./navigation/Nav"
import { useViewableHeight } from "../../hooks/useViewableHeight"
import Helmet from "react-helmet"

const Container = styled.main`
  position: fixed;
  height: ${props =>
    props.marginless
      ? `${props.viewableHeight}px`
      : `calc(${props.viewableHeight}px - 54px)`};
  width: 100%;
  top: ${props => (props.marginless ? 0 : "54px")};
  left: 0;
`

export function Layout({
  children,
  title,
  logoOverride,
  hideHamburger,
  marginless,
  leftContainer,
}) {
  const viewableHeight = useViewableHeight()

  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no, minimum-scale=1"
          />
        </Helmet>
        <Nav
          title={title}
          logoOverride={logoOverride}
          hideHamburger={hideHamburger}
          leftContainer={leftContainer}
        />
        <ToastContainer />
        <Container viewableHeight={viewableHeight} marginless={marginless}>
          {children}
        </Container>
      </ThemeProvider>
    </CookiesProvider>
  )
}

export default Layout
